import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

export const initialState = {
  cs:{
    data:[],
    loading:false,
  },
  bin:{
    data:[],
    loading:false,
  }
}
export const fetchCSData = createAsyncThunk('cs/fetch', async (selectedDate) => {
  try {
    const response =await axios.post(`${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getChronicList`,{
        date:selectedDate
      })
    return response.data.chronic_data
  } catch(err) {
    return err;
  }
 
})

export const fetchBinData = createAsyncThunk('bin/fetch', async (selectedDate) => {
    try {
      const response =await axios.post(`${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getBinList`,{
        date:selectedDate
      })
      return response.data.bin_data
    } catch(err) {
      return err;
    }
   
  })



export const csBinSlice = createSlice({
	name: 'csBin',
	initialState,
	reducers: {
	},
  extraReducers(builder) {
    builder
      .addCase(fetchCSData.pending, (state, action) => {
        state.cs.loading = true
      })
      .addCase(fetchCSData.fulfilled, (state, action) => {
        state.cs.loading = false
        state.cs.data=action.payload
        // Add any fetched posts to the array
        
      })
      .addCase(fetchCSData.rejected, (state, action) => {
        state.cs.loading = false
      })

      .addCase(fetchBinData.pending, (state, action) => {
        state.bin.loading = true
      })
      .addCase(fetchBinData.fulfilled, (state, action) => {
        state.bin.loading = false
        state.bin.data=action.payload
        // Add any fetched posts to the array
        
      })
      .addCase(fetchBinData.rejected, (state, action) => {
        state.bin.loading = false
      })
    
  }
})


export default csBinSlice.reducer