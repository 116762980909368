import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const initialState = {
  loading: false,
  superVisorList: [],
  summaryData: [],
  rawData: [],
}
function normalizeData(vehicle_data, type) {
  switch (type) {
    case 'vehicle_upkeep':
      return vehicle_data.map((data) => {
        return {
          vehicle: data.selected_vehicle,
          'after_cleaning vehicle photo': data.cleaning_photo_link,
          'vehicle elements check': data.vehicle_element_check,
          employee: data.employee,
          'employee appearance photo': data.employee_appearance_photo,
          'employee elements check': data.employee_element_check,
          'date/time': data.created_at,
        }
      })
    case 'chronic':
      return vehicle_data.map((data) => {
        return {
          'chronic spot': data.chronic_spot_id,
          'before cleaning photo': data.before_cleaning_img,
          'size of spot': data.size_of_spot,
          suctionable: data.suctionable,
          'pmc seggregated': data.PMC_Seggregated,
          'after cleaning photo': data.after_cleaning_img,
          'date/time': data.created_by,
        }
      })
    case 'bin':
        return vehicle_data.map((data) => {
          return {
            'bin': data.bin_id,
            'before cleaning photo': data.before_cleaning_img,
            'after cleaning photo': data.after_cleaning_img,
            'bin damaged': data.bin_damage,
            'date/time': data.created_by,
          }
        })
  }
}

export const fetchSummaryData = createAsyncThunk(
  'summaryData/fetch',
  async (selectedDate) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/Map/getRawDataSummary`,
        {
          date: selectedDate,
        },
      )
      if (response.data) {
        return response.data.vehicle_summary_data
      }
    } catch (err) {
      return err
    }
  },
)

export const fetchSupervisorList = createAsyncThunk(
  'superVisorList/fetch',
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/index.php/api/Map/getSupervisorList`,
      )
      if (response.data) {
        return response.data.supervisor_data
      }
    } catch (err) {
      return err
    }
  },
)

export const getThirdPartySupervisorList = createAsyncThunk(
  'getThirdPartySupervisorList/fetch',
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/index.php/api/Map/getThirdPartySupervisorList`,
      )
      if (response.data) {
        return response.data.supervisor_data
      }
    } catch (err) {
      return err
    }
  },
)

export const fetchRawData = createAsyncThunk(
  'rawData/fetch',
  async (payload) => {
    const { date, user_id, category } = payload
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/Map/getRawData`,
        {
          date: date,
          user_id: user_id,
          type: category,
        },
      )
      if (response.data) {
        return normalizeData(response.data.vehicle_data, category)
      }
      return response
    } catch (err) {
      return err
    }
  },
)

export const trackSupervisorSlice = createSlice({
  name: 'trackSupervisor',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSupervisorList.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchSupervisorList.fulfilled, (state, action) => {
        state.loading = false
        state.superVisorList = action.payload
        // Add any fetched posts to the array
      })
      .addCase(fetchSupervisorList.rejected, (state, action) => {
        state.loading = false
      })

      builder
      .addCase(getThirdPartySupervisorList.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getThirdPartySupervisorList.fulfilled, (state, action) => {
        state.loading = false
        state.superVisorList = action.payload
        // Add any fetched posts to the array
      })
      .addCase(getThirdPartySupervisorList.rejected, (state, action) => {
        state.loading = false
      })

      //   FetchSummary Data
      .addCase(fetchSummaryData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchSummaryData.fulfilled, (state, action) => {
        state.loading = false
        state.summaryData = action.payload
      })
      .addCase(fetchSummaryData.rejected, (state, action) => {
        state.loading = false
      })

      //   fetchVehicleUpKeep reducer
      .addCase(fetchRawData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchRawData.fulfilled, (state, action) => {
        state.rawData = action.payload
        state.loading = false
      })
      .addCase(fetchRawData.rejected, (state, action) => {
        state.loading = false
      })
  },
})

export default trackSupervisorSlice.reducer
