import React, { Children } from 'react'

const DOWNLOAD_SRC_PATH = '/img/others/'


export default function ModalWrapper({ showModal, children,closeModal,section}) {
  const isAddUser = section === 'addUser';
  return (
    <>
      {showModal ? (
        <>
          <div className={`justify-center ${isAddUser ? '':'items-center' } flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`}>
            <div className={`relative w-auto my-6 ${isAddUser ? ' ': 'mx-auto max-w-3xl'}`}>
              {/*content*/}
              <div className="flex flex-auto flex-col h-[50vh]">
                <div className={`border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none py-6 ${isAddUser ? 'px-5': 'px-10 '}`}>
                <div className="  absolute -top-[32px] -right-[44px]" onClick={closeModal}>
                        <img className="mr-2" src={`${DOWNLOAD_SRC_PATH}modal-close.svg`} />
                            </div>
                    {children}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-opacity-70 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  )
}
