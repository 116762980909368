import React from 'react'

const DOWNLOAD_SRC_PATH = '/img/dashboard/'

const StaffCard = ({ image, info, section }) => {

  return (
    <>
      <div className='flex flex-col items-center'>
        {image && (
          <div className=" bg-green-200 p-6 rounded-full w-[92px] h-[92px]">
            <img src={`${DOWNLOAD_SRC_PATH}${image}`} />
          </div>
        )}
      <h3 className='mt-2 text-center'>{info}</h3>
      </div>
    </>
  )
}

export default StaffCard
