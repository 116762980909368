import React from "react";


const LeftLoginSection =({info})=>{
    return(
        <div className="w-[280px] mt-2">
        <div className="mb-2">   
            <h4 className="text-green-100">Login</h4>
            <p className="text-green-50">{info.loginTime}</p>
        </div>
        <div className="mb-2">
            <h4 className="text-green-100 font-normal">Address</h4>
            <p className="text-green-50">{info.loginAddress?info.loginAddress:'-'}</p>
        </div>
        <div className="mb-2">
            <h4 className="text-green-100 font-normal">Site Name</h4>
            <p className="text-green-50">{info.loginSiteName?info.loginSiteName:'-'}</p>
        </div>
        <img src={info.loginImage}/>
        
    </div>
    )   
}

export default LeftLoginSection;