import React from "react";


const RightLogOutSection =({info})=>{
    return(
        <div className="w-[280px] mt-2">
        <div className="mb-2">   
            <h4 className="text-green-100">Logout</h4>
            <p className="text-green-50">{info.logoutTime}</p>
        </div>
        <div className="mb-2">
            <h4 className="text-green-100 font-normal">Address</h4>
            <p className="text-green-50">{info.logoutAddress?info.logoutAddress:'-'}</p>
        </div>
        <div className="mb-2">
            <h4 className="text-green-100 font-normal">Site Name</h4>
            <p className="text-green-50">{info.logoutSiteName?info.logoutSiteName:'-'}</p>
        </div>
        <img src={info.logoutImage}/>
        
    </div>
    )   
}

export default RightLogOutSection;