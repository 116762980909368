import React from 'react'

const DOWNLOAD_SRC_PATH = '/img/dashboard/'

const GridCard = ({ image, info, header, section }) => {
  let cardData = null
  if (info && info.includes('out of')) {
    cardData = info.split('out of').join('/')
  } else if (
    header === 'Staff Logged-in' ||
    header === 'Appreciation Letters'
  ) {
    cardData = info
  }
console.log(cardData);
console.log(header);
  return (
    <>
      <div className={`grid-card relative pt-11 pb-10 px-6 ${section === 'staffLogin'? 'mt-2':'mt-20'} rounded-2xl `}>
        {image && (
          <div className="absolute bottom-28 bg-green-200 p-6 rounded-full w-[92px] h-[92px]">
            <img src={`${DOWNLOAD_SRC_PATH}${image}`} />
          </div>
        )}
        {section === 'admin' ? (
          <h2 className="mt-[-1px]">{header}</h2>
        ) : (
          <>
            <h2>{cardData}</h2>
            <p>{header}</p>
          </>
        )}
        {section === 'admin' && (
          <div className="view-card flex  items-center bg-white  mt-4">
            <img className="w-14" src={`${DOWNLOAD_SRC_PATH}view.svg`} />
          </div>
        )}
      </div>
    </>
  )
}

export default GridCard
