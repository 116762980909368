import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import dayjs from 'dayjs'

export const initialState = {
  loading:false,
  potholeByCalendar:[],
  potholeByDateWise:[],
  potHoleLists:[]
}


export const fetchPotholeLists = createAsyncThunk('fetchPotholeLists/fetch', async (date) => {
  // const sDate = dayjs(new Date()).format('YYYY-MM-DD')
  try {
    const response =await axios.get(`${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getcitizenPotholeSummary` )
    if(response.data){
        return response.data.citizen_report
    }
  } catch(err) {
    return err;
  }
 
})

export const fetchPotholeByDateWise = createAsyncThunk('fetchPotholeByDateWise/fetch', async (date) => {
  // const sDate = dayjs(new Date()).format('YYYY-MM-DD')
  try {
    const response =await axios.post(`${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getPotholesDatewiseDetails`,{
      date:date
    } )
    if(response.data){
        return response.data.potholes_count[0]
    }
  } catch(err) {
    return err;
  }
 
})

export const fetchPotHoleByCalendar = createAsyncThunk('fetchPotHoleByCalendar/fetch', async (date) => {
  const sDate = dayjs(new Date()).format('YYYY-MM-DD')
    try {
      const response =await axios.post(`${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getPotholesMonthwiseDetails`,{
        date: sDate
      } )
      if(response.data){
          return response.data.potholes_count
      }
    } catch(err) {
      return err;
    }
   
  })

export const potholeSlice = createSlice({
	name: 'pothole',
	initialState,
	reducers: {
	},
  extraReducers(builder) {
    builder
      .addCase(fetchPotHoleByCalendar.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchPotHoleByCalendar.fulfilled, (state, action) => {
        state.loading = false
        state.potholeByCalendar=action.payload
        // Add any fetched posts to the array
        
      })
      .addCase(fetchPotHoleByCalendar.rejected, (state, action) => {

        state.loading = false
      })

      .addCase(fetchPotholeByDateWise.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchPotholeByDateWise.fulfilled, (state, action) => {
        state.loading = false
        state.potholeByDateWise=action.payload
        // Add any fetched posts to the array
        
      })
      .addCase(fetchPotholeByDateWise.rejected, (state, action) => {

        state.loading = false
      })

      .addCase(fetchPotholeLists.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchPotholeLists.fulfilled, (state, action) => {
        state.loading = false
        state.potHoleLists=action.payload
        // Add any fetched posts to the array
        
      })
      .addCase(fetchPotholeLists.rejected, (state, action) => {

        state.loading = false
      })

  }
})


export default potholeSlice.reducer