import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const initialState = {
  managerUserMapping: [],
  vehicleUserMapping: [],
  vehicleUserData: [],
  reportingUsers: [],
  usersSite:[],
  sites:[],
  loading: false,
  infoLading: false,
  infoSuccess:false
}

export const submitManagerToEmployee = createAsyncThunk(
  'submitManagerToEmployee/fetch',
  async (info) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/MappingManagerToUserData`,
        info,
      )
      if (response.data) {
        return response.data.manager_to_user
      }
    } catch (err) {
      return err
    }
  },
)

export const fetchManagerToUserMapping = createAsyncThunk(
  'managerUser/fetch',
  async (selectedDate) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getManagerToUserMappingData`,
      )
      if (response.data) {
        return response.data.manager_to_user
      }
    } catch (err) {
      return err
    }
  },
)

export const fetchReportingUser = createAsyncThunk(
  'fetchReportingUser/fetch',
  async (selectedDate) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getMappingUserData`,
      )
      if (response.data) {
        return response.data.user_list
      }
    } catch (err) {
      return err
    }
  },
)


export const fetchUserToVehicleMapping = createAsyncThunk(
  'UserToVehicleMapping/fetch',
  async (selectedDate) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getUserToVehicleMappingData`,
      )
      if (response.data) {
        return response.data.vehicle_data
      }
    } catch (err) {
      return err
    }
  },
)

export const getUserVehicleData = createAsyncThunk(
  'getUserVehicleData/fetch',
  async (selectedDate) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getUserVehicleData`,
      )
      if (response.data) {
        return response.data.user_list
      }
    } catch (err) {
      return err
    }
  },
)

export const submitUserVehicleData = createAsyncThunk(
  'submitUserVehicleData/fetch',
  async (info) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/MappingUserToVehicleData`,
        info,
      )
      if (response.data) {
        return response.data.manager_to_user
      }
    } catch (err) {
      return err
    }
  },
)

export const getUserSiteData = createAsyncThunk(
  'getUserSiteData/fetch',
  async (selectedDate) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getUserSiteData`,
      )
      if (response.data) {
        return response.data.user_list
      }
    } catch (err) {
      return err
    }
  },
)


export const getSitesData = createAsyncThunk(
  'getSitesData/fetch',
  async (selectedDate) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getSiteData`,
      )
      if (response.data) {
        return response.data.site_data
      }
    } catch (err) {
      return err
    }
  },
)

export const submitAttendanceSites = createAsyncThunk(
  'submitAttendanceSites/fetch',
  async (info) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/MappingUserToSiteData`,
        info,
      )
      if (response.data) {
        return response.data.manager_to_user
      }
    } catch (err) {
      return err
    }
  },
)

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchManagerToUserMapping.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchManagerToUserMapping.fulfilled, (state, action) => {
        state.loading = false
        state.managerUserMapping = action.payload
      })
      .addCase(fetchManagerToUserMapping.rejected, (state, action) => {
        state.loading = false
      })

      .addCase(fetchReportingUser.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchReportingUser.fulfilled, (state, action) => {
        state.loading = false
        state.reportingUsers = action.payload
      })
      .addCase(fetchReportingUser.rejected, (state, action) => {
        state.loading = false
      })
      /// submit to manager data
      .addCase(submitManagerToEmployee.pending, (state, action) => {
        state.loading = true
        state.infoLading = true
        state.infoSuccess= false
      })
      .addCase(submitManagerToEmployee.fulfilled, (state, action) => {
        state.loading = false
        state.infoLading = false
        state.infoSuccess=true
      })
      .addCase(submitManagerToEmployee.rejected, (state, action) => {
        state.loading = false
        state.infoLading = false
        state.infoSuccess=false
      })


      ///

      .addCase(fetchUserToVehicleMapping.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchUserToVehicleMapping.fulfilled, (state, action) => {
        state.loading = false
        state.vehicleUserMapping = action.payload
      })
      .addCase(fetchUserToVehicleMapping.rejected, (state, action) => {
        state.loading = false
      })


      .addCase(getUserVehicleData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getUserVehicleData.fulfilled, (state, action) => {
        state.loading = false
        state.vehicleUserData = action.payload
      })
      .addCase(getUserVehicleData.rejected, (state, action) => {
        state.loading = false
      })


/// submit to manager data
.addCase(submitUserVehicleData.pending, (state, action) => {
  state.loading = true
  state.infoLading = true
  state.infoSuccess= false
})
.addCase(submitUserVehicleData.fulfilled, (state, action) => {
  state.loading = false
  state.infoLading = false
  state.infoSuccess=true
})
.addCase(submitUserVehicleData.rejected, (state, action) => {
  state.loading = false
  state.infoLading = false
  state.infoSuccess=false
})
      
      ////

      .addCase(getUserSiteData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getUserSiteData.fulfilled, (state, action) => {
        state.loading = false
        state.usersSite = action.payload
      })
      .addCase(getUserSiteData.rejected, (state, action) => {
        state.loading = false
      })
////
      .addCase(getSitesData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getSitesData.fulfilled, (state, action) => {
        state.loading = false
        state.sites = action.payload
      })
      .addCase(getSitesData.rejected, (state, action) => {
        state.loading = false
      })
      

      /// submit to manager data
      .addCase(submitAttendanceSites.pending, (state, action) => {
        state.loading = true
        state.infoLading = true
        state.infoSuccess= false
      })
      .addCase(submitAttendanceSites.fulfilled, (state, action) => {
        state.loading = false
        state.infoLading = false
        state.infoSuccess=true
      })
      .addCase(submitAttendanceSites.rejected, (state, action) => {
        state.loading = false
        state.infoLading = false
        state.infoSuccess=false
      })
  },
})

export default adminSlice.reducer
