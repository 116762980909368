import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

export const initialState = {
  loading:false,
  byWeekAttendance:[],
  userslist:[],
  singleUserAtendance:{
    data:{},
    loading:false
  },
  loginLogoutInfo:{
    loading:false,
    data:{}
  }
}
export const fetchAttendance = createAsyncThunk('attendance/fetch', async (selectedDate) => {
  try {
    const response =await axios.post(`${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getLatestUserAttendanceForSelectedDateWeek` ,{
      date:selectedDate
    })
    return response.data
  } catch(err) {
    return err;
  }
 
})

export const fetchuserslist = createAsyncThunk('userList/fetch', async () => {

  try {
    const response =await axios.post(`${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getUserList`,
    {
      date:new Date()
    }
     )
  if(response){
    return response.data

  }
  return response
  }catch(err){
    return err;
  }
  
})

export const fetchSingleUserList = createAsyncThunk('userListSingle/fetch', async ({user_id,selectedDateOfMonth}) => {
  
  const response =await axios.post(`${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getUserById` ,{
    user_id :user_id,
    date:selectedDateOfMonth
  })
  if(response){
    return response.data

  }
  return response
})

export const fetchUserLoginLogout = createAsyncThunk('loginLogoutUser/fetch', async ({date,user_id}) => {
  
  const response =await  axios.post(`${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getUserInOutDetails`,{
    date:date,
    user_id:user_id
  })
  if(response?.data){
    return response.data.user_attendance_details[0]

  }
  return response
})





export const attendanceSlice = createSlice({
	name: 'attendance',
	initialState,
	reducers: {
	},
  extraReducers(builder) {
    builder
      .addCase(fetchAttendance.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchAttendance.fulfilled, (state, action) => {
        state.loading = false
        state.byWeekAttendance=action.payload
        // Add any fetched posts to the array
        
      })
      .addCase(fetchAttendance.rejected, (state, action) => {

        state.loading = false
      })
      .addCase(fetchuserslist.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchuserslist.fulfilled, (state, action) => {
        state.userslist=action.payload
        // Add any fetched posts to the array
        
      })
      .addCase(fetchuserslist.rejected, (state, action) => {
        state.loading = false
      })
      /// Single user List
      .addCase(fetchSingleUserList.pending, (state, action) => {
        state.singleUserAtendance.loading = true
      })
      .addCase(fetchSingleUserList.fulfilled, (state, action) => {
        state.singleUserAtendance.data=action.payload
        state.singleUserAtendance.loading = false
        // Add any fetched posts to the array
        
      })
      .addCase(fetchSingleUserList.rejected, (state, action) => {
        state.singleUserAtendance.loading = false
      })

        /// user Login Logout
        .addCase(fetchUserLoginLogout.pending, (state, action) => {
          state.loginLogoutInfo.loading = true
        })
        .addCase(fetchUserLoginLogout.fulfilled, (state, action) => {
          state.loginLogoutInfo.data=action.payload
          state.loginLogoutInfo.loading = false
          // Add any fetched posts to the array
          
        })
        .addCase(fetchUserLoginLogout.rejected, (state, action) => {
          state.loginLogoutInfo.loading = false
          state.loginLogoutInfo.data={}
        })
  }
})


export default attendanceSlice.reducer