import React from "react";
import LeftLoginSection from './LeftLoginSection'
import RightLogOutSection from './RightLogoutSection'
import { Loading } from 'components/shared'
import dayjs from 'dayjs'



const DOWNLOAD_SRC_PATH = '/img/others/'

export default function Modal({showModal,closeModal,loginLogoutInfo}) {
  const info = loginLogoutInfo?.data
  const loading = loginLogoutInfo?.loading
//   const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              { loading ? <div className="flex flex-auto flex-col h-[50vh]">
        <Loading loading={true} />
      </div>:
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none py-6 px-10">
						<div className="  absolute -top-[32px] -right-[44px]" onClick={closeModal}>
                        <img className="mr-2" src={`${DOWNLOAD_SRC_PATH}modal-close.svg`} />
                            </div>

                {/*header*/}
                  <h3 className="text-xl font-semibold text-center  mb-5 text-green-100">
                  {dayjs(info.date).format(' D MMMM YYYY')}
                  </h3>
                  <img className="mr-2" src={`${DOWNLOAD_SRC_PATH}Line.svg`} />
                {/*body*/}
                <div className="flex flex-col items-center my-4">

                <img className="mr-2 mb-2 w-[90px] h-[90px]" src={info.profile} />
                
                <h3 className="text-xl font-semibold  mb-1 text-green-200">{info.name}</h3>
                <div>
                    <span className="text-green-50 font-medium">{info.service_provider_name}</span>
                    <span className="mx-1">|</span>
                    <span className="text-green-50 font-medium">{info.user_sub_category}</span>
                    <span className="mx-1">|</span>
                    <span className="text-green-50 font-medium">{info.site_name}</span>
                </div>
                </div>
                <div>
                
                <div className="flex justify-center">
                    <LeftLoginSection info={info}/>
                  <img className="mx-7" src={`${DOWNLOAD_SRC_PATH}verticale-line.svg`} />

                    <RightLogOutSection info={info}/>
                </div>
                </div>
               
                {/*footer*/}
    
              </div>
}
            </div>
          </div>
          <div className="bg-opacity-70 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}