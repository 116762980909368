import { theme } from 'twin.macro'

const twColor = theme`colors`

export const COLOR_1 = twColor.green['600'] 
export const COLOR_2 = twColor.gray['500']
export const COLOR_5 = twColor.red['500']

export const COLOR_1_LIGHT = twColor.green['100'] 
export const COLOR_2_LIGHT = twColor.gray['100']
export const COLOR_5_LIGHT = twColor.red['100']

export const COLORS = [
	COLOR_1,
	COLOR_2,
]

export const COLORS_LIGHT = [
	COLOR_1_LIGHT,
	COLOR_2_LIGHT,
]