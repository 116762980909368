import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

export const initialState = {
  loading:false,
  production_history:[],
  consumption_history:[],
  tanker_history:[],
  tanker_atm_history:[],
  production_history_sum :{
    plant: 'Total',
    pwf_reading: 0,
    rwf_reading: 0,
    total_reading: 0
  },
  consumption_history_sum :{
    atm_name: 'Total',
    liter_filling: 0,
  },
  tanker_history_sum:{
    vehicle_name:'',
    no_of_trips:0,
    liter_filling:0
  },
  tanker_atm_history_sum:{
    atm_name:'',
    no_of_trips:0,
    liter_filling:0
  }
}

// Define reducer function

function getConsumptionHistorySum(data){
  const reducer = (accumulator, currentValue) => {
    if (currentValue.liter_filling !== null) {
      console.log('')
        accumulator.liter_filling += parseInt( currentValue.liter_filling);
    }
    return accumulator;
  };
  
  // Initial values for accumulator
  const initialValue = {
    atm_name: 'Total',
    liter_filling: 0,
  };
  
  // Use reduce to calculate sums
  const result = data.reduce(reducer, initialValue);

  return result
}


function getProductionHistorySum(data){
  const reducer = (accumulator, currentValue) => {
    if (currentValue.pwf_reading !== null) {
        accumulator.pwf_reading += parseInt( currentValue.pwf_reading);
    }
    if (currentValue.rwf_reading !== null) {
        accumulator.rwf_reading += parseInt( currentValue.rwf_reading);
    }
    accumulator.total_reading += parseInt( currentValue.total_reading);
    return accumulator;
  };
  
  // Initial values for accumulator
  const initialValue = {
    pwf_reading: 0,
    rwf_reading: 0,
    total_reading: 0,
    plant:'Total'
  };
  
  // Use reduce to calculate sums
  const result = data.reduce(reducer, initialValue);

  return result
}

function getTankerHistorySum(data){
  const reducer = (accumulator, currentValue) => {
    if (currentValue.no_of_trips !== null) {
        accumulator.no_of_trips += parseInt( currentValue.no_of_trips);
    }

    if (currentValue.liter_filling !== null) {
      accumulator.liter_filling += parseInt( currentValue.liter_filling);
  }
    return accumulator;
  };
  
  // Initial values for accumulator
  const initialValue = {
    vehicle_name:'Total',
    no_of_trips:0,
    liter_filling:0
  };
  
  // Use reduce to calculate sums
  const result = data.reduce(reducer, initialValue);

  return result
}

function getTankerAtmHistorySum(data){
  const reducer = (accumulator, currentValue) => {
    if (currentValue.liter_filling !== null) {
        accumulator.liter_filling += parseInt( currentValue.liter_filling);
    }
    return accumulator;
  };
  
  // Initial values for accumulator
  const initialValue = {
    atm_name: 'Total',
    liter_filling: 0,
  };
  
  // Use reduce to calculate sums
  const result = data.reduce(reducer, initialValue);

  return result
}




export const fetchProductionHistory = createAsyncThunk('fetchProductionHistory/fetch', async (date) => {
  try {
    const response =await axios.post(`${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getProductionHistory`,{
      date:date
    } )
    if(response.data){
      

    // Calculate sum of pwf_reading
    
        return response.data.production_history
    }
  } catch(err) {
    return err;
  }
 
})

export const getTankerFillingDistributionHistory = createAsyncThunk('getTankerFillingDistributionHistory/fetch', async (date) => {
    try {
      const response =await axios.post(`${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getTankerFillingHistory`,{
        date:date
      } )
      if(response.data){
          return response.data.tanker_history
      }
    } catch(err) {
      return err;
    }
   
  })


  export const getTankerATMFillingDistributionHistory = createAsyncThunk('getTankerATMFillingDistributionHistory/fetch', async (date) => {
    try {
      const response =await axios.post(`${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getTankerATMFillingHistory`,{
        date:date
      } )
      if(response.data){
          return response.data.tanker_atm_history
      }
    } catch(err) {
      return err;
    }
   
  })
  // 

  export const fetchConsumptionHistory = createAsyncThunk('fetchConsumptionHistory/fetch', async (date) => {
    try {
      const response =await axios.post(`${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getATMConsumptionHistory`,{
          type:date
      } )
      if(response.data){
          return response.data.atm_consumption_history
      }
    } catch(err) {
      return err;
    }
   
  })



export const waterSlice = createSlice({
	name: 'pothole',
	initialState,
	reducers: {
	},
  extraReducers(builder) {
    builder
      .addCase(fetchProductionHistory.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchProductionHistory.fulfilled, (state, action) => {
        state.loading = false
        state.production_history=action.payload
        state.production_history_sum=getProductionHistorySum(action.payload)
        // Add any fetched posts to the array
        
      })
      .addCase(fetchProductionHistory.rejected, (state, action) => {

        state.loading = false
      })

      .addCase(getTankerFillingDistributionHistory.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getTankerFillingDistributionHistory.fulfilled, (state, action) => {
        state.loading = false
        state.tanker_history=action.payload
        state.tanker_history_sum=getTankerHistorySum(action.payload);
        // Add any fetched posts to the array
        
      })
      .addCase(getTankerFillingDistributionHistory.rejected, (state, action) => {

        state.loading = false
      })

//////////
      .addCase(getTankerATMFillingDistributionHistory.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getTankerATMFillingDistributionHistory.fulfilled, (state, action) => {
        state.loading = false
        state.tanker_atm_history=action.payload
        state.tanker_atm_history_sum=getTankerAtmHistorySum(action.payload);
        // Add any fetched posts to the array
        
      })
      .addCase(getTankerATMFillingDistributionHistory.rejected, (state, action) => {

        state.loading = false
      })


      .addCase(fetchConsumptionHistory.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchConsumptionHistory.fulfilled, (state, action) => {
        state.loading = false
        state.consumption_history=action.payload
        state.consumption_history_sum=getConsumptionHistorySum(action.payload)
        // Add any fetched posts to the array
        
      })
      .addCase(fetchConsumptionHistory.rejected, (state, action) => {

        state.loading = false
      })
  }
})


export default waterSlice.reducer