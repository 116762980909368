import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Td = React.forwardRef((props, ref) => {
	const { children, className, asElement: Component, ...rest } = props


	const tdClass = classNames(
		Component !== 'td' && 'td',
		className
	)
	if(typeof children === 'string') {
		return(
			<Component className={tdClass} ref={ref}  {...rest}>
			{children}
		</Component>
		)
		
	}
	if(!Array.isArray(children) && ( (children.props.cell && children.props.cell.column.Header ==='CS CLEARED') || 
	(children.props.cell && children.props.cell.column.Header ==='BIN CLEARED') )){
		props.style.totalFlexWidth=126;
		props.style.width=126;
		props.style.totalLeft=11;
	}
	return (
		<Component className={tdClass} ref={ref}  {...rest}>
			{children}
		</Component>
	)
})

Td.propTypes = {
	asElement: PropTypes.string
};

Td.defaultProps = {
	asElement: 'td'
}


export default Td
