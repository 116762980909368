import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

export const initialState = {
  loading:false,
  fuelDetailsByType:[],
  fuelDetails:[],
}

export const fetchFuelDetailsByType = createAsyncThunk('fuelDetailsByType/fetch', async (type) => {
  try {
    const response =await axios.post(`${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getFuelDetailsTypeWise`,{
        type:type
    } )
    if(response.data){
        return response.data.fuel_report
    }
  } catch(err) {
    return err;
  }
 
})

export const fetchFuelDetails = createAsyncThunk('fuelDetails/fetch', async (type) => {
  try {
    const response =await axios.post(`${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getFuelDetailsTypeWise`,{
        type:'Diesel'
    } )
    if(response.data){
        return response.data.fuel_report
    }
  } catch(err) {
    return err;
  }
 
})


export const fuelSlice = createSlice({
	name: 'fuel',
	initialState,
	reducers: {
	},
  extraReducers(builder) {
    builder
      .addCase(fetchFuelDetailsByType.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchFuelDetailsByType.fulfilled, (state, action) => {
        state.loading = false
        state.fuelDetailsByType=action.payload
        // Add any fetched posts to the array
        
      })
      .addCase(fetchFuelDetailsByType.rejected, (state, action) => {

        state.loading = false
      })

      .addCase(fetchFuelDetails.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchFuelDetails.fulfilled, (state, action) => {
        state.loading = false
        state.fuelDetails=action.payload
        // Add any fetched posts to the array
        
      })
      .addCase(fetchFuelDetails.rejected, (state, action) => {

        state.loading = false
      })
  }
})


export default fuelSlice.reducer