export const getColor=(percentage,isNOPercentageHeader)=> {
    if(isNOPercentageHeader){
        return '#00A611';
    }
    else if (percentage > 90) {
        return "#00A611";
    } else if (percentage >= 80 && percentage <= 90) {
        return "#FF6B00";
    } else {
        return "#FF3939";
    }
}

export const calculatePercentage=(part, total)=> {
    return ((part / total) * 100).toFixed(2);
}