import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const initialState = {
  loading: false,
  citizenReport: [],
}
export const fetchCitizenReport = createAsyncThunk(
  'citizenReport/fetch',
  async (selectedDate) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/getcitizenSummary`,
      )
      if (response.data.citizen_report) {
        return response.data.citizen_report
      }
    } catch (err) {
      return err
    }
  },
)

export const postStatusData = createAsyncThunk(
  'postStatusData/fetch',
  async (formValues) => {
    // let formData = new FormData()

    // formData.append('image', formValues)
    // formData.append('complaint_id', 13510)
    // formData.append('status', 'REPORTED')
    // formData.append('user_id', 3)
    // formData.append('comment', 'test prop')
    // let data =JSON.stringify(...formData)
    // console.log('====formdata==',formData)
    // const config = {
    //   headers: {
    //     'content-type': 'multipart/form-data',
    //   },
    // }
// for(const key of formData.keys()){
//   console.log('key',key);

// }
//     for (const value of formData.values()) {
//       console.log(value);
//     }

    let obj ={
      'complaint_id':formValues.complaint_id,
      'status':formValues.status,
      'user_id':formValues.user_id,
      'comment':formValues.comment
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/index.php/api/TeamApccDashboard/updateCitizenSummary`,
        {
          ...obj,
        },
      )
      return response.data
    } catch (err) {
      return err
    }
  },
)

export const citizenReportSlice = createSlice({
  name: 'citizenReport',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCitizenReport.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchCitizenReport.fulfilled, (state, action) => {
        state.loading = false
        state.citizenReport = action.payload
        // Add any fetched posts to the array
      })
      .addCase(fetchCitizenReport.rejected, (state, action) => {
        state.loading = false
      })

      .addCase(postStatusData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(postStatusData.fulfilled, (state, action) => {
        state.loading = false
        // Add any fetched posts to the array
      })
      .addCase(postStatusData.rejected, (state, action) => {
        state.loading = false
      })
  },
})

export default citizenReportSlice.reducer
