import React, { useCallback } from 'react'
import {calculatePercentage,getColor} from '../../dashboard/getPercentage'

const DOWNLOAD_SRC_PATH = '/img/dashboard/newDashboard/'


const Circle = props => {

    const {
        strokeColor,
        width,
        children,
        colorCircle ='#E4E4E4',
        info,
        header,
        image,
        activeArrow=false,
        handleClick,
        isYesterdayEnable=true,
    } = props

    const isNOPercentageHeader = header === 'Appreciation Letters' || header === 'Volunteers Registered';
    let yesterDay = null;
    let pecentage =null;

    
    if (info && info.includes('out of')) {
        yesterDay = info.split('out of').join('/')
        const total = info.split('out of');
        pecentage = calculatePercentage(total[0],total[1])
    }else {
        yesterDay = info
    }

    
    let colorSlice = getColor(pecentage,isNOPercentageHeader);
    console.log('header',header)
      console.log('colorSlice',colorSlice)

    const viewBoxSize = 100;
    const radius = 42;
    const strokeWidth = 10;
    const strokeDashArray = 264;
    const strokeDashOffset = strokeDashArray - (pecentage / 100) * strokeDashArray;

    const progressStrokeClass = `progress-circle-stroke text-${strokeColor}`

    return (
        <div className="progress-circle" style={{width: '100%'}}>
            <span className="progress-circle-info"> 
            <div className="flex items-center flex-col">
            {image && (
          <div className="mt-6">
            <img src={`${DOWNLOAD_SRC_PATH}${image}`} />
          </div>
        )}
          <span className='font-semibold mt-3 text-center' style={{fontSize:'11px'}}>{header}</span>
         {!isYesterdayEnable ?  <span className='font-semibold text-center mt-3 text-[#979797]' style={{fontSize:'10px'}}>{yesterDay}</span>:
          <span className='font-semibold text-center mt-3 text-[#979797]' style={{fontSize:'10px'}}>Yesterday :{yesterDay}</span>}
          {pecentage && <h3 className='mt-3' style={{color:colorSlice}}>{pecentage}%</h3>}
      </div>
      
            </span>
            {activeArrow && (
          <div className="h-[70px] w-[70px]  absolute bottom-[-12px] left-[35%] cursor-pointer" onClick={handleClick}>
            <img src={`${DOWNLOAD_SRC_PATH}/arrow.svg`} />
          </div>
        )}
            <svg
        role="progressbar"
        width="100%"
        height="100%"
        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
        aria-valuemin="0"
        aria-valuemax="100"
        aria-valuenow={pecentage}
      >
        <circle cx="50%" cy="50%" r={radius} shape-rendering="geometricPrecision" fill="none" stroke={colorCircle} stroke-width={strokeWidth}></circle>
        <circle
          cx="50%"
          cy="50%"
          r={radius}
          shape-rendering="geometricPrecision"
          className={`pie-circle-3`}
          fill="none"
          stroke-width={strokeWidth}
          stroke-dashoffset={strokeDashOffset}
          stroke-dasharray={strokeDashArray}
          style={{ transform: 'rotate(-90deg)', transformOrigin: '50% 50%' }}
          stroke={colorSlice}
          data-angel={pecentage}
        ></circle>
     
      </svg>
      
        </div>
    )
}

export default Circle
