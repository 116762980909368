import React from 'react'
import './citizenComplaint.css'

const DOWNLOAD_SRC_PATH = '/img/dashboard/'

const CitizenCompaliant = ({ image, info, header, onClickRoute,id ,section}) => {
  let cardData = null
  if (info && info.includes('out of')) {
    cardData = info.split('out of').join('/')
  }
  return (
    <>
      <div className="complaint-card relative pt-11 pb-10 px-6 mt-8 rounded-2xl ">
        <div className="">
          <img src={`${DOWNLOAD_SRC_PATH}${image}`} />
        </div>
        <div className="ml-8">
          <h4 className="sm:text-2xl text-[0.9rem]">{header}</h4>
          <p className="font-semibold text-3xl">{cardData}</p>
        </div>

       { section === 'admin' && <div className="view-card flex  items-center bg-white  mt-4" onClick={()=> onClickRoute(id)}>
          <img className='w-14' src={`${DOWNLOAD_SRC_PATH}view.svg`} />
        </div>}
      </div>
    </>
  )
}

export default CitizenCompaliant
